import Input from "../elements/Input";
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import Macros from "../../Macros";
//import ReactCompareImage from 'react-compare-image';
//import { ImgComparisonSlider } from '@img-comparison-slider/react';
//import { ImgComparisonSlider } from 'img-comparison-slider';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import ImageUploading from "react-images-uploading";
//import ImageUploader from "react-images-upload";

//Not ready for use in general
//import { RMIUploader } from "react-multiple-image-uploader";

//Results in compile errors? requires react-progress-Button
//import ImagesUploader from "../../image-uploader";
//import "../../image-uploader/styles/styles.css";
//import "../../image-uploader/styles/font.css";
//

import Progress from "react-progress";

import axios from "axios";
//import zlib from "browserify-zlib";
//import LZString from "lz-string";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

var mailRegex =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
var mailValidate = function (email) {
  if (!email) return false;

  var emailParts = email.split("@");

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  var domainParts = address.split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  if (!mailRegex.test(email)) return false;

  return true;
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);
  const mailSupplied = useState("");
  const mailValidated = mailValidate(mailSupplied[0]);
  //const mailRef = useRef("");

  const onEmail = (e) => {
    console.log("onEmail");
    mailSupplied[1](e.target.value);
  };
  const uploadState = React.useState(0);
  //useEffect(() => {
  //  if (uploadState[0] == 1) {
  //    mailSupplied[1]("");
  //    mailValidated[1](false);
  //  }
  //}, [uploadState[0]]);

  const outstandingUpload = useRef(undefined);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    if (2 <= uploadState[0]) {
      alert("Still busy!");
    } else {
      e.preventDefault();
      setVideomodalactive(false);
    }
  };

  //const onUpload = (data) => {
  //  alert("Upload files", data);
  //};
  //const onSelect = (data) => {
  //  alert("Select files", data);
  //};
  //const onRemove = (id) => {
  //  alert("Remove image id", id);
  //};
  //

  const uploadCarouselIndex = useRef(0);

  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const busyLocal = useState(false);

  //const executedLater = useState(undefined);
  //useEffect(() => {
  //  //if (executedLater[0]) {
  //  //  alert("aaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  //  //  //executedLater[0]();
  //  //  executedLater[1](undefined);
  //  //}
  //}, [executedLater[0]]);

  const imageUpdate = useState([]);
  useEffect(() => {
    if (imageUpdate[0].length) {
      console.log("image updates");
      const [imageList, addUpdateIndex] = imageUpdate[0];
      imageUpdate[1]([]);
      if (addUpdateIndex !== undefined) {
        const i = addUpdateIndex.pop();
        //console.log("onUploaderChange add", imageList, addUpdateIndex, i);
        uploadCarouselIndex.current = i;
      } else {
        //console.log("onUploaderChange delete", imageList, addUpdateIndex);
        if (uploadCarouselIndex.current >= imageList.length) {
          uploadCarouselIndex.current = uploadCarouselIndex.current - 1;
        }
      }
      setImages(imageList);
      //console.log("onUploaderChange 2");
      //console.log(
      //  (document.getElementById("file-upload-trigger").disabled = false)
      //);
      busyLocal[1](false);
    }
  }, [imageUpdate[0]]);

  const onUploaderChange = (imageList, addUpdateIndex) => {
    // data for submit
    if (addUpdateIndex) busyLocal[1](true);
    setTimeout(() => {
      imageUpdate[1]([imageList, addUpdateIndex]);
    }, 200);
    //console.log(
    //  (document.getElementById("file-upload-trigger").disabled = true)
    //);
    //console.log("onUploaderChange");
    //setTimeout(() => {
    //executedLater[1](() => {
    //  console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
    //  if (addUpdateIndex !== undefined) {
    //    const i = addUpdateIndex.pop();
    //    //console.log("onUploaderChange add", imageList, addUpdateIndex, i);
    //    uploadCarouselIndex.current = i;
    //  } else {
    //    //console.log("onUploaderChange delete", imageList, addUpdateIndex);
    //    if (uploadCarouselIndex.current >= imageList.length) {
    //      uploadCarouselIndex.current = uploadCarouselIndex.current - 1;
    //    }
    //  }
    //  setImages(imageList);
    //  //console.log("onUploaderChange 2");
    //  //console.log(
    //  //  (document.getElementById("file-upload-trigger").disabled = false)
    //  //);
    //  busyLocal[1](false);
    //});
    //},0);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const [pictures, setPictures] = useState([]);

  const onDrop = (picture) => {
    //console.log("onDrop", picture);
    //console.log("onDrop", JSON.stringify(picture));
    //console.log('onDrop s',pictures);
    //setPictures([...pictures, picture]);
    setPictures(picture);
  };
  const uploadProgress = useState(0);
  //console.log(images.length);
  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              {/*<span className="text-color-primary">Realverse</span>:*/}
              Real <span className="text-color-primary">Visual AI </span>
              for {/*<br/>*/}{" "}
              <span className="text-color-primary">{Macros.Real_estate}</span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Our deep artificial intelligence technology applies directly to
                the only indispensable type of visual communication -- static
                photographs.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  {
                    //      <Button tag="a" color="primary" wideMobile href="https://">
                    //    Learn more
                    //  </Button>
                  }
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    onClick={openModal}
                    style={
                      {
                        //height: "2.3em",
                        //lineHeight: "1.3em",
                        //fontSize: "large",
                      }
                    }
                  >
                    Upload photos
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/*<a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >*/}
            {/*<Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />*/}
            {/*<ReactCompareImage
                 leftImage={require('./../../assets/images/video-placeholder.jpg')} rightImage={require('./../../assets/images/video-placeholder.jpg')} />*/}
            <Carousel showThumbs={false}>
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    style={{ clipPath: "inset(6%)" }}
                    src={require("./../../assets/images/pre/sUpm50B0Cv.jpeg")}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    style={{ clipPath: "inset(6%)" }}
                    src={require("./../../assets/images/post/tmo-sUpm50B0Cv.jpeg")}
                  />
                }
              />
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    style={{ clipPath: "inset(6%)" }}
                    src={require("./../../assets/images/pre/gdWsoa4sVZ.jpeg")}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    style={{ clipPath: "inset(6%)" }}
                    src={require("./../../assets/images/post/tmo-gdWsoa4sVZ.jpeg")}
                  />
                }
              />
            </Carousel>
            {/*</a>*/}
          </div>
          <Modal
            show={videoModalActive}
            handleClose={closeModal}
            classnames="modal-video"
            style={
              {
                //width: "1200px",
                //'maxWidth' : "1200px"
                //height: "80vh",
              }
            }
          >
            {
              //<ImagesUploader
              //  url="/upload/multiple"
              //  optimisticPreviews
              //  onLoadEnd={(err) => {
              //    if (err) {
              //      alert(err);
              //    }
              //  }}
              //  label="Upload multiple images"
              ///>
              <ImageUploading
                multiple
                value={images}
                onChange={onUploaderChange}
                onError={() => {
                  alert("Trouble processing the selected file(s)");
                }}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                maxFileSize={11000000}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    {images.length > 0 && uploadState[0] > 0 ? (
                      <div
                        style={{
                          margin: 0,
                          padding: 0,
                          //visibility: uploadState[0] > 1 ? "hidden" : "normal",
                        }}
                      >
                        <Progress
                          height="5"
                          percent={
                            3 == uploadState[0]
                              ? 99.9999
                              : (uploadState[0] > 1 ? 1 : 0) * 0.9999 +
                                99 * uploadProgress[0]
                          }
                        />
                        {3 == uploadState[0] ? (
                          <h1>Success!</h1>
                        ) : (
                          <ButtonGroup>
                            <span>E-mail: &nbsp;</span>
                            <Input
                              type="email"
                              placeholder="E-mail address to receive results"
                              style={{ minWidth: "20em" }}
                              onChange={
                                onEmail
                                //(e) => {
                                //  mailRef.current = e.target.value;
                                //  console.log(e.target.value);
                                //}
                              }
                              disabled={uploadState[0] > 1}
                              value={
                                mailSupplied[0]
                                //mailRef.current
                              }
                            ></Input>
                            <ButtonGroup>
                              <Button
                                style={{
                                  color: "#151719",
                                  backgroundColor: "#24E5AF",
                                  width: "12em",
                                }}
                                wideMobile
                                onClick={() => {
                                  console.log(
                                    "actual upload",
                                    mailSupplied[0],
                                    images
                                  );
                                  console.log({
                                    mail: mailSupplied[0],
                                    images: images,
                                  });
                                  const payload = {
                                    mail: mailSupplied[0],
                                    images: images.map((x) => {
                                      return {
                                        data: x.data_url,
                                        //data: LZString.compress(x.data_url), //results in 502
                                        name: x.file.name,
                                      };
                                    }),
                                  };
                                  const controller = new AbortController();
                                  outstandingUpload.current = controller;

                                  const onError = (more) => {
                                    alert(
                                      "Unfortunately, the upload of your photos has failed. \n" +
                                        more +
                                        "If error persists, please retry with fewer and smaller photos. The total size of images uploaded can not exceed 4MB"
                                    );
                                    uploadState[1](1);
                                  };
                                  axios
                                    .post("/upload-multiple", payload, {
                                      signal: controller.signal,
                                      onUploadProgress: function (e) {
                                        uploadProgress[1](e.loaded / e.total);
                                      },
                                    })
                                    .then((e) => {
                                      //console.log("then", e);
                                      //console.log(
                                      //  "SAME?",
                                      //  JSON.stringify(payload) == e.data.body,
                                      //  "sizes",
                                      //  e.data.body.length
                                      //);
                                      if ("DONE" == e.data) {
                                        uploadState[1](3);
                                      } else {
                                        onError("");
                                      }
                                    })
                                    .catch((e) => {
                                      if (e instanceof axios.CanceledError) {
                                      } else {
                                        //console.log(e);
                                        onError(e.message + ".\n");
                                      }
                                    })
                                    .finally(() => {
                                      //console.log("upload finalized!");
                                      outstandingUpload.current = undefined;
                                      uploadProgress[1](0);
                                    });
                                  uploadState[1](2);
                                }}
                                disabled={uploadState[0] > 1 || !mailValidated}
                              >
                                {uploadState[0] > 1
                                  ? "Uploading..."
                                  : mailValidated
                                  ? "Confirm and upload"
                                  : mailSupplied[0].length
                                  ? "Need correct mail"
                                  : "Please provide mail"}
                              </Button>
                              <Button
                                style={{
                                  color: "#ECEDED",
                                  backgroundColor: "#FF6171",
                                  width: "7em",
                                }}
                                wideMobile
                                onClick={() => {
                                  if (uploadState[0] > 1) {
                                    outstandingUpload.current.abort();
                                    uploadState[1](1);
                                  } else {
                                    uploadState[1](0);
                                  }
                                }}
                              >
                                {uploadState[0] > 1 ? "Cancel" : "Go back"}
                              </Button>
                            </ButtonGroup>
                          </ButtonGroup>
                        )}
                        <p
                          style={{
                            opacity: uploadState[0] == 2 ? "50%" : undefined,
                            fontSize: "smaller",
                            lineHeight: "1.5em",
                          }}
                        >
                          Your photos will be mailed to the provided address
                          after being post-processed by our AI core. This
                          usually takes anywhere between a few minutes to an
                          hour, occasionally as long as 24 hours due to
                          availability of the AI core. By any means, rest
                          assured that you will NOT be subscribed to any mail
                          lists. Kindly check your spam box if not receiving the
                          results after prolonged time.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <ButtonGroup>
                          <Button
                            color="primary"
                            id="file-upload-trigger"
                            wideMobile
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={(e) => {
                              //e.target.disabled = true;
                              //busyLocal[1](true);
                              //console.log(
                              onImageUpload(e);
                              //);
                              //alert(2);
                            }}
                            {...dragProps}
                            disabled={busyLocal[0]}
                            style={{ width: images.length ? "12em" : "20em" }}
                          >
                            {busyLocal[0]
                              ? "Busy"
                              : images.length
                              ? "Add more photos"
                              : "Add photos to get started"}
                          </Button>
                          {images[uploadCarouselIndex.current] &&
                          0 == uploadState[0] ? (
                            <Button
                              style={{
                                color: "#ECEDED",
                                backgroundColor: "#FF6171",
                              }}
                              wideMobile
                              onClick={() =>
                                onImageRemove(uploadCarouselIndex.current)
                              }
                            >
                              Remove this photo
                            </Button>
                          ) : (
                            ""
                          )}
                          {images.length ? (
                            <Button
                              style={{
                                color: "#151719",
                                backgroundColor: "#24E5AF",
                              }}
                              wideMobile
                              onClick={() => {
                                console.log("upload");
                                uploadState[1](images.length > 0);
                              }}
                            >
                              Process all photos
                            </Button>
                          ) : (
                            ""
                          )}
                        </ButtonGroup>
                        <p
                          style={{
                            marginTop: "0.5em",
                            marginBottom: "0",

                            fontSize: "smaller",
                            opacity: "70%",
                          }}
                        >
                          For this free service, the total size of images
                          uploaded can not exceed 4MB
                        </p>
                      </div>
                    )}

                    {
                      //<div
                      //  style={{
                      //    position: "relative",
                      //    width: "100%",
                      //    height: "100%",
                      //    left: 0,
                      //    top: 0,
                      //    background: "black",
                      //    zIndex: 9999,
                      //  }}
                      //></div>
                    }
                    {0 == images.length ? (
                      ""
                    ) : (
                      <div
                        style={
                          uploadState[0] > 1 && uploadState[0] != 3
                            ? {
                                //background: "red",
                                pointerEvents: "none",
                                userSelect: "none",
                                opacity: "20%",
                              }
                            : {}
                        }
                      >
                        <Carousel
                          infiniteLoop={false}
                          showThumbs={false}
                          useKeyboardArrows
                          onChange={(i) => {
                            console.log("Carousel", i);
                            uploadCarouselIndex.current = i;
                          }}
                          onLoad={() => {
                            console.log("onLoad");
                          }}
                          selectedItem={uploadCarouselIndex.current}
                        >
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              classnames="image-item"
                              style={
                                {
                                  //objectFit: "contain",
                                  //width: "800px",
                                  //height: "50vh",
                                }
                              }
                            >
                              {
                                //<div className="image-item__btn-wrapper">
                                //  <Button onClick={() => onImageUpdate(index)}>
                                //    Update
                                //  </Button>
                                //  <Button onClick={() => onImageRemove(index)}>
                                //    Remove
                                //  </Button>
                                //</div>
                              }
                              <img
                                style={{
                                  objectFit: "contain",
                                  height:
                                    uploadState[0] > 2
                                      ? "30vh"
                                      : uploadState[0] > 0
                                      ? "50vh"
                                      : "70vh",
                                }}
                                src={image["data_url"]}
                                alt=""
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    )}
                    {/*
                        imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <Image src={image["data_url"]} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <Button onClick={() => onImageUpdate(index)}>
                            Update
                          </Button>
                          <Button onClick={() => onImageRemove(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))*/}
                  </div>
                )}
              </ImageUploading>
            }
            {uploadState[0] != 3 ? (
              ""
            ) : (
              <div style={{ marginTop: "1em" }}>
                <ButtonGroup>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    onClick={() => {
                      uploadState[1](0);
                      setImages([]);
                      //closeModal();
                      setVideomodalactive(false);
                    }}
                  >
                    Close dialog
                  </Button>
                  <Button
                    tag="a"
                    color="secondary"
                    wideMobile
                    onClick={() => {
                      uploadState[1](0);
                      setImages([]);
                      //closeModal();
                    }}
                  >
                    Upload more
                  </Button>
                </ButtonGroup>
              </div>
            )}
            {
              //<ImageUploader
              //  {...props}
              //  withIcon={true}
              //  withPreview
              //  onChange={onDrop}
              //  imgExtension={[".jpg", ".jpeg", ".jpe", ".png"]}
              //  maxFileSize={11000000 /*show less*/}
              ///>
            }
          </Modal>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
