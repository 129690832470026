import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "AI-empowered architectural photography",
    paragraph:
      "With AI, we endeavor to automate everything other than the point-and-shoot. " +
      "The physical point-and-shoot is also to be as quick and easy as possible, with little tricks to learn.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-01.svg")
                          .default
                      }
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Less hassle, less cost</h4>
                  <p className="m-0 text-sm">
                    Potentially spend less time actually taking the pictures
                    than dealing with another person. Uploading to your listings
                    can be automated as well.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-02.svg")
                          .default
                      }
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Point & shoot, NO tripod</h4>
                  <p className="m-0 text-sm">
                    Our AI is designed to cope with less-than-ideal raw images
                    taken in bare hands. Just walk, aim, press, walk, aim,
                    press...
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-03.svg")
                          .default
                      }
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Geometric autocorrect</h4>
                  <p className="m-0 text-sm">
                    Our AI keeps the verticals straight so that you don't even
                    have to hold the camera very level.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-04.svg")
                          .default
                      }
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">HDR without bracketing</h4>
                  <p className="m-0 text-sm">
                    Time to stop, if you have being paying for or spending too
                    much time on HDR. HDR without multiple shots is totally
                    within the capability of modern AI.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-05.svg")
                          .default
                      }
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Faster to market {/*Keep your copyrights*/}
                  </h4>
                  <p className="m-0 text-sm">
                    {/*You keep the copyrights of photos you take.  We only ask to use the photos to demonstrate our technology.*/}
                    Schedule is now in your hands because the machines do not
                    sleep or rest. Get it done already when your competitors are
                    still waiting for an appointment.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-06.svg")
                          .default
                      }
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Yes, Deep Learning</h4>
                  <p className="m-0 text-sm">
                    No exaggeration, our AI uses real Deep Learning. Supply your
                    clients with the deepest tech, with a lower budget than
                    out-sourcing. What could be better?
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-06.svg")
                          .default
                      }
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Keep your copyrights</h4>
                  <p className="m-0 text-sm">
                    You may keep the copyrights of photos you take. We only ask
                    to use the photos to demonstrate our technology. Your logos
                    can be stamped on automatically.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-06.svg")
                          .default
                      }
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Bulk discounts</h4>
                  <p className="m-0 text-sm">
                    Get a professional price by having a real estate agent
                    license. Our AI can also keep track of and adapt to
                    individual cameras.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-06.svg")
                          .default
                      }
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Worry free guarantee</h4>
                  <p className="m-0 text-sm">Pay for the results you like.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
